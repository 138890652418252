
<script>
import {Line} from "vue-chartjs";

export default {
  name: "reportingWidget",
  extends: Line,
  props:['labels', 'values'],
    data () {
    return {
      gradient: null
    }
  },
  mounted() {
    
    this.gradient = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450)

    this.gradient.addColorStop(0, 'rgba(108, 190, 62, 0.5)')
    this.gradient.addColorStop(0.5, 'rgba(108, 190, 62, 0.25)');
    this.gradient.addColorStop(1, 'rgba(108, 190, 62, 0)');

    this.setGradient();

    this.renderLineChart();
  },
  methods:{
    setGradient(){
        this.$emit('displayGradient', this.gradient);
    },
    renderLineChart() {
      this.renderChart(
          {
            labels: this.labels,
            datasets: [
              {
                // label: "Data 1",
                data: this.values,
                backgroundColor: this.gradient,
                borderColor: "rgba(108, 190, 62, 1)",
                pointBackgroundColor: "rgba(108, 190, 62, 1)",
                pointBorderWidth: 2,
              }
            ],
          },
          {
            legend:{
              display: false,
            },
            tooltips:{
              backgroundColor: 'rgba(255, 255, 255, 1)',
              titleFontColor: '#000',
              titleFontSize: 15,
              bodyFontColor: '#6CBE3E',
              bodyFontSize: 15,
              cornerRadius: 6,
              displayColors: false,
              yPadding: 15,
              xPadding: 15,
              titleSpacing: 8,
              bodySpacing: 8,
              callbacks: {
                label: function(tooltipItem, data) {
                  // dataset label
                  let label = data.datasets[tooltipItem.datasetIndex].label || '';

                  if (label) {
                    label += ': ';
                  }
                  let value = Math.floor(tooltipItem.yLabel).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                  label += (value + ' (kWh cumac)');
                  return label;
                }
              }
            },
            scales: {
              yAxes: [
                {
                  ticks: {
                    callback(value) {
                      return Math.floor(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                    }
                  },
                  scaleLabel: {
                    display: true,
                    labelString: 'Volume total déposé (kWh cumac)',
                    fontColor: '#939393',
                    fontSize: 18,
                    fontFamily: 'Avenir'
                  },
                  gridLines: {
                    color: '#E0E7EE'
                  },
                }],
              xAxes: [
                {
                  scaleLabel: {
                    display: true,
                    labelString: 'Temps (mois)',
                    fontColor: '#939393',
                    fontSize: 18,
                    fontFamily: 'Avenir'
                  },
                  gridLines: {
                    display: false,
                  }
                }]
            },
            responsive: true,
            maintainAspectRatio: false,
            title: {
              display: false,
              text: "My Data",
            },
          }
      );

    }
  },
  watch: {
    values() {
      this.$data._chart.destroy();
      this.renderLineChart();
    }
  }

};
</script>
